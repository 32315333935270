.btn-custom {
  color: #F99E3C;
  border: 1px solid #F99E3C;
  background-color: transparent;
  border-radius: 4px;
  margin-right: 3px;
  outline:none;
}

.btn-custom:hover  {
  background-color: rgba(249,158,60,0.5);
  border-color: rgba(249,158,60,0.1);
  color: white;
  outline:none;
}

.btn-custom:focus  {
  outline:none;
}

.content-width {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: space-between;*/
  color: #000;
  font-family: Optima;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  border-bottom: 4px solid rgb(0, 0, 0);
  width: 320px;
  padding-bottom: 20px;
  padding-top: 14px;
  text-align: left;
}

.dialog-custom {
  border-radius: 16px !important;
}

.dialog-item-text-right {
  flex: 0 0 auto !important;
  color: #666F83;
}

.dialog-item-text-right:hover {
  color: #F99E3C;
}

.dialog-item-text-right-hover {
  color: #F99E3C !important;
}


.dialog-item-text-left {
  font-size: 16px;
  font-weight: bold;
  font-family: Optima;
}

.list-container {
  display: flex;
  flex-direction: column;
}

.item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  padding: 16px 10px;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  cursor: pointer;
}

.item-container:last-child {
  border: none;
}

/*.item-container:hover {*/
/*  background: #f5f5f5;*/
/*}*/

.dialog-item-text-left img {
  width: 32px;
  margin-right: 14px;
}

.bottom-text {
  font-size: 16px;
  font-family: Roboto;
  color: #858B9C;
  margin-top: 100px;
  margin-bottom: 16px;
  text-align: center;
}

.bottom-text-container {
  font-size: 16px;
  font-family: Roboto;
  color: #858B9C;
  margin-top: 100px;
  margin-bottom: 16px;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bottom-text-right {
  color: #FF8212;
}

.addr-container {
  font-size: 14px;
  color: #FF8212;
  font-family: PingFangSC-Regular, PingFang SC;
  cursor: pointer;
}

.addr-container img {
  width: 20px;
  margin-right: 6px;
  margin-left: 4px;
}

.asset-account-container {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.asset-addr-container {
  display: flex;
  font-size: 32px;
  font-family: Roboto;
  font-weight: 500;
  color: #111A34;
  height: 32px;
  line-height: 32px;
}

.asset-addr-container img {
  width: 24px;
  margin-right: 6px;
}

.asset-change-container {
  display: flex;
  flex-direction: row;
}

.asset-change-left {
  margin-top: 5px;
}

.asset-change-btn {
  background: #FCF0DC;
  border-radius: 4px;
  color: #FF8212;
  padding: 0 4px;
  font-size: 14px;
  margin-left: 105px;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
}

.asset-copy {
  color: #FF8212;
  font-size: 14px;
  font-family: Roboto;
}

.asset-copy-margin {
  margin-right: 24px;
  cursor: pointer;
}

.asset-copy-right {
  cursor: pointer;
}

.asset-token-container {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 20px;
}

.asset-token-amount-item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.row-container {
  display: flex;
  flex-direction: row;
}

.asset-token-title {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 300;
  color: #666F83;
}

.asset-token-name {
  font-size: 18px;
  font-family: Roboto;
  color: #111A34;
  height: 18px;
}

.asset-deposit-name {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF8212;
  cursor: pointer;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-9 {
  margin-left: 9px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.padding-16 {
  padding: 16px;
}

.asset-amount-text {
  font-size: 16px;
  font-family: Roboto;
  color: #858B9C;
}

.asset-token-list {
  background: #F9FAFB;
  border-radius: 8px;
  margin-top: 8px;
}

.asset-trans-text {
  font-size: 14px;
  font-family: Roboto;
  color: #41485D;
}

.asset-trans-item {
  display: flex;
  flex-direction: row;
}

.right-head{
  display: flex;
  align-items: center;
}
.right-head .recover{
  margin-left: 8px;
}
.right-head .recover img{
  cursor: pointer;
}
.right-head .recover img:hover{
  background-image: url('../../assets/images/recover_hover.png');
}
