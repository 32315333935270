.social {
  width: 100%;
  max-width: 235px;

  @media (max-width: 576px){
    max-width: 170px;
  }
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    height: 15px;
  }
}
