.select-account-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
  }

  .dialog-wrapper {
    width: 562px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.102298);
    border-radius: 16px !important;
    & > div {
      padding: 25px 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .dialog-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    & > h3 {
      font-family: Roboto;
      font-weight: bold;
      font-size: 20px;
      line-height: 40px;
      color: #070A0D;
    }

    & > img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .dialog-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 470px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .account-list-item {
    margin-bottom: 20px;
    padding: 0 20px;
    width: 492px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background: #F8F8F8;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 8px;

    &:hover {
      border: 1px solid #4BA870;
    }

    & > span {
      font-family: Roboto;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      text-align: right;
      color: #070A0D;

    }
  }

  .selected {
    border: 2px solid #4BA870 !important;
  }

  .account-list-item-right {
    display: flex;
    align-items: center;

    & > span {
      font-family: Roboto;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      text-align: right;
      color: rgba(7, 10, 13, 0.5);
    }

    img {
      width: 24px;
      height: 24px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
